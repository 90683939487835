const utils = {
  isReference(val) {
    return typeof val === 'object' && val !== null;
  },

  isObject(val) {
    return Object.prototype.toString.call(val).slice(8, 14) === 'Object';
  },

  isArray(val) {
    return Object.prototype.toString.call(val).slice(8, 13) === 'Array';
  },

  isDate(val) {
    return Object.prototype.toString.call(val).slice(8, 12) === 'Date';
  },

  // YY-MM-DD
  handleDateObject(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (month < 10) month = '0' + month;
    if (day < 10) day = '0' + day;

    const dateStr = year + '-' + month + '-' + day;
    return dateStr;
  },

  // YY-MM-DD-HH-MM-SS
  handleDateObjectTime(date, division = '-') {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    if (month < 10) {
      month = '0' + month;
    }
    if (day < 10) {
      day = '0' + day;
    }
    if (hour < 10) {
      hour = '0' + hour;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    const dateStr = year + division + month + division + day + ' ' + hour + ':' + minutes + ':' + seconds;
    return dateStr;
  },

  // 验证数字
  isPositiveNumber(val) {
    const RE = /^(?:0|[1-9]\d*)(?:\.\d*[1-9])?$/;
    if (val && !RE.test(val)) {
      return false;
    } else {
      return true;
    }
  },
  // 验证正整数
  isPositiveInteger(val) {
    const RE = /^[0-9]+$/;
    if (val && !RE.test(val)) {
      return false;
    } else {
      return true;
    }
  },

  // 验证手机号
  isPhoneAvailable(val) {
    var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    if (!myreg.test(val)) {
      return false;
    } else {
      return true;
    }
  },

  // 验证邮箱
  isEmailAvailable(val) {
    var myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    if (!myreg.test(val)) {
      return false;
    } else {
      return true;
    }
  },

  // 克隆
  deepCloneObject(val, newVal) {
    const ret = newVal || (utils.isObject(val) ? {} : []);
    for (const key in val) {
      const el = val[key];
      if (utils.isReference(el)) {
        ret[key] = utils.isObject(el) ? {} : [];
        utils.deepCloneObject(el, ret[key]);
      } else {
        ret[key] = el;
      }
    }
    return ret;
  },

  // 根据需要赋值
  getKeyValue(obj1, obj2) {
    const newObj = {};
    for (const key in obj1) {
      const value = obj2[key] ? obj2[key] : '';
      newObj[key] = value;
    }

    return newObj;
  },

  // 获取一个月有多少天
  getDays(year, month) {
    const days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      days[1] = 29;
    }
    return days[month - 1];
  },

  // 拼装参数
  assemblyParams(params) {
    let _str = '';
    for (const key in params) {
      if (params[key]) {
        _str += '&' + key + '=' + params[key];
      }
    }
    return _str ? _str.substr(1) : '';
  },

  // 校验是否为中文
  isNumberLetter(str) {
    var zg = /[\u4E00-\u9FA5\uF900-\uFA2D]/;
    if (!zg.test(str)) {
      return false;
    }
    return true;
  },

  // 获得上周的开始日期
  getLastWeekStartDate() {
    var now = new Date(); // 当前日期
    var nowDayOfWeek = now.getDay(); // 今天本周的第几天
    var nowDay = now.getDate(); // 当前日
    var nowMonth = now.getMonth(); // 当前月
    var nowYear = now.getFullYear(); // 当前年
    var lastMonthDate = new Date(); // 上月日期
    lastMonthDate.setDate(1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    var weekStartDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 7);
    return utils.handleDateObject(weekStartDate) + ' 00:00:00';
  },

  // 获得上周的结束日期
  getLastWeekEndDate() {
    var now = new Date(); // 当前日期
    var nowDayOfWeek = now.getDay(); // 今天本周的第几天
    var nowDay = now.getDate(); // 当前日
    var nowMonth = now.getMonth(); // 当前月
    var nowYear = now.getFullYear(); // 当前年
    var lastMonthDate = new Date(); // 上月日期
    lastMonthDate.setDate(1);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);

    var weekEndDate = new Date(nowYear, nowMonth, nowDay - nowDayOfWeek - 1);
    return utils.handleDateObject(weekEndDate) + ' 23:59:59';
  },

  // 获得上月开始时间
  getLastMonthStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    let month = new Date().getMonth();
    month = month === 0 ? 12 : month;
    month = month < 10 ? '0' + month : month;
    year = month === 0 ? year - 1 : year;
    const _date = year + '-' + month + '-01 00:00:00';
    return _date;
  },

  // 获得上月结束时间
  getLastMonthEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    let month = new Date().getMonth();
    month = month === 0 ? 12 : month;
    year = month === 0 ? year - 1 : year;
    const days = utils.getDays(year, month);
    month = month < 10 ? '0' + month : month;
    const _date = year + '-' + month + '-' + days + ' 23:59:59';
    return _date;
  },

  // 获得第一季度的开始日期
  getFirstQuarterStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-01-01 00:00:00';
    return _date;
  },

  // 获得第一季度的结束日期
  getFirstQuarterEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-03-31 23:59:59';
    return _date;
  },

  // 获得第二季度的开始日期
  getSecondQuarterStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-04-01 00:00:00';
    return _date;
  },

  // 获得第二季度的结束日期
  getSecondQuarterEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-06-30 23:59:59';
    return _date;
  },

  // 获得第三季度的开始日期
  getThirdQuarterStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-07-01 00:00:00';
    return _date;
  },

  // 获得第三季度的结束日期
  getThirdQuarterEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-09-30 23:59:59';
    return _date;
  },

  // 获得第四季度的开始日期
  getFourthQuarterStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-10-01 00:00:00';
    return _date;
  },

  // 获得第四季度的结束日期
  getFourthQuarterEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const month = new Date().getMonth();
    year = month === 0 ? year - 1 : year;
    const _date = year + '-12-31 23:59:59';
    return _date;
  },

  // 获得去年的开始日期
  getLastYearStartDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const _date = year - 1 + '-01-01 00:00:00';
    return _date;
  },

  // 获得去年的结束日期
  getLastYearEndDate() {
    var now = new Date(); // 当前日期
    var year = now.getFullYear(); // 当前年
    const _date = year - 1 + '-12-31 23:59:59';
    return _date;
  }
};

export default utils;
