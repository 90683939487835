const options = {
  legend: {
    left: 'center',
    bottom: '15',
    itemGap: 30,
    itemWidth: 40
  },
  tooltip: {
    trigger: 'axis'
  },
  grid: {
    top: '40',
    left: '60',
    right: '70',
    bottom: '50'
  },
  xAxis: {
    type: 'category',
    axisTick: {
      alignWithLabel: true
    },
    axisLabel: {
      color: '#A5B9FF'
    },
    axisLine: {
      lineStyle: {
        color: 'rgba(165, 185, 255, 0.3)'
      }
    },
    data: []
  },
  yAxis: [{
    type: 'value',
    name: '',
    axisTick: {
      show: false
    },
    axisLabel: {
      color: '#A5B9FF'
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(165, 185, 255, 0.3)'
      }
    },
    axisLine: {
      lineStyle: {
        color: 'rgba(165, 185, 255, 0.3)'
      }
    }
  }],
  series: []
}
export default options
