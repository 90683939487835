import axios from 'axios';
// import { trimString, getSceneStr } from '@/utils/common'

// 创建实例
const Instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  params: {
    _: Date.now() // 接口强制去缓存
  }
});

// 请求数据修改
// 递归过滤字符串类型的空格
Instance.defaults.transformRequest.push(data => {
  if (data) {
    if (Object.prototype.toString.call(data) === '[object FormData]') {
      return data;
    }
    try {
      const tmp = JSON.parse(data);
      // tmp = trimString(tmp)
      data = JSON.stringify(tmp);
    } catch (err) {
      // throw new Error(err);
    }
  }
  return data;
});

Instance.interceptors.request.use(
  config => {
    // 可能存在的场景事件
    // const sceneStr = getSceneStr()
    // if (sceneStr) {
    //   if (!config.params) {
    //     config.params = {}
    //   }
    //   config.params.scene_str = sceneStr
    // }
    // 根据请求方法构建请求参数和数据
    const token = sessionStorage.getItem('talents_data_view_token');
    if (token) {
      config.headers.common['x-data-view-token'] = `${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// http response 拦截器
Instance.interceptors.response.use(
  async response => {
    // 【手动处理】数据
    if (response.config.manual === true) {
      return response.data;
    }
    const code = response.data && response.data.code;
    const codeMap = [];
    if (codeMap.includes(code)) {
      return response.data;
    }
    if (code === 200) {
      return response.data.data;
    } else {
      return Promise.reject(response.data);
    }
  },
  async error => {
    const data = error.response && error.response.data;
    if (data) {
      return Promise.reject(data);
    }
    return Promise.reject(new Error('网络连接失败，请刷新重试'));
  }
);

export default Instance;
