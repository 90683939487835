<template>
  <div class="index">
    <div class="header">
      {{ basicInfo.name || '大数据平台' }}
      <span class="date-time">{{dateTime}}</span>
    </div>
    <div class="content">
      <div class="top">
        <!-- 求职者 -->
        <div class="job_wanted bg" :class="loading.seeker ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            求职者
          </div>
          <div class="lately">
            <div class="small-title">最新加入求职者</div>
            <div class="lately-conetnt">
              <div v-for="(item, index) in latelyList" :key="index">
                <img :src="item.avatar" alt="" />
                <div>
                  <p class="name">{{ item.name }}</p>
                  <p class="job">
                    <span v-if="item.age">{{ item.age }}岁</span>
                    <span v-if="item.edu">·{{ item.edu }}</span>
                    <span v-if="item.work_exp">·{{ item.work_exp }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="edu_experience">
              <div>
                <div class="small-title">求职者·学历</div>
                <div id="edu"></div>
              </div>
              <div>
                <div class="small-title">求职者·工作经验</div>
                <div id="experience"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- 招聘数据 -->
        <div class="info">
          <!-- 数据 -->
          <div :class="[privacy ? 'info-detail bg privacy-info-detail' : 'info-detail bg', isLeiyang ? 'customStyle':'', loading.basic ? 'loading' :'']" >
            <div v-if="!loading.basic" class="line" >
              <div class="number-item">
                <p class="count color_FF6295">
                  <count-to
                    :start-val="originalInfo.userTotal"
                    :end-val="basicInfo.userTotal"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">总用户数</p>
              </div>
              <div class="number-item">
                <p class="count color_35CEFC">
                  <count-to
                    :start-val="originalInfo.personalTotal"
                    :end-val="basicInfo.personalTotal"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">个人用户</p>
              </div>
              <div class="number-item">
                <p class="count color_35CEFC">
                  <count-to
                    :start-val="originalInfo.resumeTotal"
                    :end-val="basicInfo.resumeTotal"
                    separator=""
                    :duration="1000"
                  />
                  </p>
                <p class="name">用户简历</p>
              </div>
              <div class="number-item">
                <p class="count color_FCB30C">
                  <count-to
                    :start-val="originalInfo.companyTotal"
                    :end-val="basicInfo.companyTotal"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">企业用户</p>
              </div>
              <div class="number-item">
                <p class="count color_FCB30C">
                  <count-to
                    :start-val="originalInfo.jobTotal"
                    :end-val="basicInfo.jobTotal"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">招聘职位</p>
              </div>
            </div>
            <div class="line last" v-if="isLeiyang && !loading.basic">
              <div class="number-item">
                <p class="count color_FF6295">
                  <count-to
                    :start-val="0"
                    :end-val="basicInfo.infoViews"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">岗位信息点击浏览合计</p>
              </div>
              <div class="number-item">
                <p class="count color_FCB30C">
                  <count-to
                    :start-val="0"
                    :end-val="basicInfo.infoApplys"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">求职者网络投递简历合计</p>
              </div>
              <div class="number-item">
                <p class="count color_35CEFC">
                  <count-to
                    :start-val="0"
                    :end-val="basicInfo.interviews"
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">用人单位发出网络面试邀请合计</p>
              </div>
              <div class="number-item">
                <p class="count color_FF6295">
                  <count-to
                    :start-val="0"
                    :end-val="basicInfo.vips  "
                    separator=""
                    :duration="1000"
                  />
                </p>
                <p class="name">会员企业</p>
              </div>
            </div>
          </div>
          <!-- 隐私号 -->
          <div v-if="privacy" class="privacy">
            <img class="icon" src="./../../assets/svgs/privacy.svg" alt="" />
            号码隐私保护
            <div class="count" :class="loading.basic ? 'loading' :''">
              <img v-for="(item, index) in count" :key="index" :src="require(`./../../assets/svgs/privacy_count_${item - 0}.svg`)" alt="">
              <img src="./../../assets/svgs/privacy_count.svg" alt="">
            </div>
          </div>
          <!-- 最近1年招聘职位数趋势 -->
            <div :class="privacy ? 'job-trend bg privacy-job-trend' : 'job-trend bg'">
                <div class="title">
                  <img src="./../../assets/svgs/title_icon.svg" alt="" />
                  最近1年新增职位数趋势
                </div>
                <div class="trend" :class="loading.trendJobs ? 'loading' :''">
                  <div id="trend"></div>
                </div>
            </div>
          </div>

        <!-- 招聘企业 -->
        <div class="company bg" :class="loading.hotCompany ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            招聘企业
          </div>

          <div class="company-echarts">
            <div>
              <div class="small-title">企业规模</div>
              <div id="scale"></div>
            </div>
            <div>
              <div class="small-title">行业分布</div>
              <div id="distribution"></div>
            </div>
          </div>

          <!-- 热门企业 -->
          <div class="hot-company">
            <div class="small-title">热门企业</div>
            <div ref="hotCompanyBox" class="hot-list">
              <div ref="hotCompany" class="list">
                <div v-for="(item, index) in companyList" :key="index" class="list-info">
                  <div class="no">{{ index + 1 }}</div>
                  <div class="company-name">{{ item.name }}</div>
                  <div class="job">职位：{{ item.effective_jobs || 0 }} 个</div>
                  <div class="delivery">被投递：{{ item.apply_total || 0 }} 次</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom">
        <!-- 热门搜索 -->
        <div class="hot_search bg" :class="loading.hotKeywords ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            热门搜索词
          </div>
          <div id="word"></div>
        </div>

        <div class="actual">
          <div class="seekers bg">
            <div class="title">
              <img src="./../../assets/svgs/title_icon.svg" alt="" />
              求职者实时数据
            </div>
            <div ref="box" class="seekers-list">
              <div ref="seekers" class="list">
                <div v-for="(item, index) in seekersActualList" :key="index" class="list-info">
                  <div class="name">{{ item.who }}</div>
                  <div class="opear">{{ item.do }}</div>
                  <div class="jobs">{{ item.for }}{{ item.patch }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="company bg">
            <div class="title">
              <img src="./../../assets/svgs/title_icon.svg" alt="" />
              企业实时数据
            </div>
            <div class="company-list">
              <div ref="company" class="list">
                <div v-for="(item, index) in companyActualList" :key="index" class="list-info">
                  <div class="company">{{ item.who }}</div>
                  <div class="do">{{ item.do }}</div>
                  <div class="patch">{{ item.for }}{{ item.patch }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 热门区域 -->
        <div element-loading-background="rgba(0, 0, 0, 0.6)" class="hot_area bg" :class="loading.basic ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            热门区域
            <span>单位：家</span>
          </div>
          <div id="area"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to';
import barLine from './../../assets/js/bar_line';
import rate from './../../assets/js/rate';
import word from './../../assets/js/word';
import utils from './../../utils/utils';
import Js2WordCloud from 'js2wordcloud';
import { Promise } from 'q';
import { setTimeout } from 'timers';
// import { setInterval } from 'timers';

export default {
  name: 'Index',
  components: {
    CountTo
  },
  data() {
    return {
      dateTime: '',
      timerDate: null,
      loading: {
        basic: false,
        seeker: false,
        hotCompany: false,
        hotKeywords: false,
        trendJobs: false,
        hotRegion: false,
        actualCompany: false,
        actualseeker: false,
        info: false
      },
      timerInfo: null,
      timerActual: null,

      originalInfo: {
        userTotal: 0,
        personalTotal: 0,
        resumeTotal: 0,
        companyTotal: 0,
        jobTotal: 0
      },
      basicInfo: {},
      latelyList: [],

      // 求职者·学历
      eduEcharts: null,
      eduRates: utils.deepCloneObject(rate),

      // 求职者·工作经验
      experienceEcharts: null,
      experienceRates: utils.deepCloneObject(rate),

      // 招聘职位数趋势
      trendEcharts: null,
      trendLines: utils.deepCloneObject(barLine),

      // 企业规模
      scaleEcharts: null,
      scaleRates: utils.deepCloneObject(rate),

      // 行业分布
      distributionEcharts: null,
      distributionRates: utils.deepCloneObject(rate),

      // 热门企业
      companyList: [],
      scrollHotCompanyHeight: 0,
      timerHotCompany: null,
      boxHotCompanyHeight: 0,

      seekersActualList: [],
      companyActualList: [],
      scrollSeekerHeight: 0,
      timerSeeker: null,
      boxSeekerHeight: 0,
      scrollCompanyHeight: 0,
      timerCompany: null,
      boxCompanyHeight: 0,

      // 热门区域
      areaEcharts: null,
      areaBars: utils.deepCloneObject(barLine),

      hotWordEcharts: null,
      hotWords: utils.deepCloneObject(word),

      privacy: true,
      count: []
    };
  },
  mounted() {
    if (this.timerDate) {
      window.clearInterval(this.timerDate);
    }
    if (this.timerInfo) {
      window.clearInterval(this.timerInfo);
    }
    if (this.timerActual) {
      window.clearInterval(this.timerActual);
    }
    if (this.timerHotCompany) {
      window.clearInterval(this.timerHotCompany);
    }
    const token = this.$route.query.token || '';
    sessionStorage.setItem('talents_data_view_token', decodeURIComponent(token));
    // if (window.screen.width / window.screen.height < 4 / 3) {
    //   this.$router.push({ path: '/h5-data-view', query: { token: sessionStorage.getItem('talents_data_view_token') } });
    // }
    this.init();
  },
  beforeDestroy() {
    window.clearInterval(this.timerDate);
    window.clearInterval(this.timerInfo);
    window.clearInterval(this.timerActual);
  },
  computed: {
    isLeiyang() {
      return ['localhost', 'jy.leiyangw.cn', 'job-test.myjiedian.com'].includes(window.location.hostname)
    }
  },
  methods: {
    IsH5() {
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        this.$router.push({ path: '/h5-data-view', query: { token: sessionStorage.getItem('talents_data_view_token') } });
      };
    },
    init() {
      this.dateTime = utils.handleDateObjectTime(new Date(), '.')
      this.getBasicInfo();
      this.getSeekerInfo();
      this.getHotCompany();
      this.getHotKeywords();
      this.getTrendJobs();
      this.getActualseeker();
      this.getActualCompany();

      // 开启定时器
      this.infoInterval();
      this.actualInterval();
      this.timeInterval()
    },

    timeInterval() {
      if (this.timerDate) {
        window.clearInterval(this.timerDate);
      }
      this.timerDate = setInterval(() => {
        this.dateTime = utils.handleDateObjectTime(new Date(), '.')
      }, 1000);
    },

    infoInterval() {
      if (this.timerInfo) {
        window.clearInterval(this.timerInfo);
      }
      this.timerInfo = setInterval(() => {
        this.getBasicInfo();
        this.getSeekerInfo();
        this.getHotCompany();
        this.getHotKeywords();
        this.getTrendJobs();
      }, 10 * 60 * 1000);
    },

    actualInterval() {
      if (this.timerActual) {
        window.clearInterval(this.timerActual);
      }
      this.timerActual = setInterval(() => {
        this.getActualseeker();
        this.getActualCompany();
      }, 60 * 1000);
    },

    // 获取基础数据
    async getBasicInfo() {
      this.loading.basic = true;
      try {
        const formInfo = await this.$api.platform.getInfo();
        const basicInfo = await this.$api.platform.getBasicInfo();
        const hotRegionInfo = await this.$api.platform.getHotRegion({ limit: 6 });
        const privacyInfo = await this.$api.platform.getPrivacyCount();
        Promise.all([formInfo, basicInfo, hotRegionInfo, privacyInfo]).then((res) => {
          const formData = res[0] || {}
          const basicData = res[1] || {}
          const hotRegionData = res[2] || {}
          const privacyData = res[3] || {}
          // 基础数据
          const basicInfo = {
            name: formData.siteName || basicData.dashboard.name || '大数据平台',
            personalTotal: formData.personalTotal ? formData.personalTotal * 1 + basicData.personalTotal : basicData.personalTotal,
            resumeTotal: formData.resumeTotal ? formData.resumeTotal * 1 + basicData.resumeTotal : basicData.resumeTotal,
            jobTotal: formData.jobTotal ? formData.jobTotal * 1 + basicData.jobTotal : basicData.jobTotal,
            companyTotal: formData.companyTotal ? formData.companyTotal * 1 + basicData.companyTotal : basicData.companyTotal,
            infoViews: formData.infoViews ? Number(formData.infoViews) + Number(basicData.infoViews) : Number(basicData.infoViews),
            infoApplys: formData.infoApplys ? Number(formData.infoApplys) + Number(basicData.infoApplys) : Number(basicData.infoApplys),
            interviews: formData.interviews ? Number(formData.interviews) + Number(basicData.interviews) : Number(basicData.interviews),
            vips: formData.vips ? formData.vips * 1 + basicData.vips : +basicData.vips
          }
          basicInfo.userTotal = basicInfo.personalTotal + basicInfo.companyTotal
          this.privacy = privacyData.is_open || false
          const privacy = formInfo.privacy ? formInfo.privacy - 0 : 0
          const countPrivacy = privacyData.count ? privacyData.count - 0 : 0
          const countStr = privacy + countPrivacy === 0 ? (parseInt(Math.random() * 10000)) + '' : (privacy + countPrivacy) + ''
          const count = countStr ? countStr.split('') : ['0']
          this.count = count

          // setTimeout(() => {
          //   this.originalInfo = basicInfo
          // }, 5000)

          this.basicInfo = basicInfo
          document.title = basicInfo.name;

          // 热门区域
          const list = hotRegionData.list || []
          const hotCompanyTotal = formData.companyTotal * 1
          if (hotCompanyTotal) {
            const number1 = hotCompanyTotal % list.length
            const number2 = (hotCompanyTotal - number1) / list.length
            list.forEach((el, i) => {
              const value = i === 0 ? number1 + number2 : number2
              el.value = el.value ? el.value + value : value
            })
          }
          this.getAreaBar(list);

          this.loading.basic = false;
        })
      } catch (err) {
        this.loading.basic = false;
        console.log(err);
      }
    },

    // 获取求职者、学历分布、工作经验分布
    async getSeekerInfo() {
      this.loading.seeker = true;
      try {
        const data = await this.$api.platform.getSeekerInfo();
        this.latelyList = data.newly || [];
        this.getEduRate(data.eduPercentile);
        this.getExperienceRate(data.expPercentile);
        this.loading.seeker = false;
      } catch (err) {
        this.loading.seeker = false;
        console.log(err);
      }
    },

    // 获取学历百分比
    getEduRate(eduPercentile = []) {
      this.eduRates.legend.left = '5%';
      this.distributionRates.legend.textStyle.width = '50';
      this.distributionRates.legend.textStyle.overflow = 'truncate';
      this.eduRates.series = [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '62%'],
          center: ['70%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          data: eduPercentile
        }
      ];
      this.initEduRate();
    },

    initEduRate() {
      this.eduEcharts = this.$echarts.init(document.getElementById('edu'));
      this.eduEcharts.setOption(this.eduRates);
    },

    // 获取工作经验百分比
    getExperienceRate(expPercentile = []) {
      this.experienceRates.legend.left = '5%';
      this.distributionRates.legend.textStyle.width = '50';
      this.distributionRates.legend.textStyle.overflow = 'truncate';
      this.experienceRates.series = [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '62%'],
          center: ['70%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          data: expPercentile
        }
      ];
      this.initExperienceRate();
    },

    initExperienceRate() {
      this.experienceEcharts = this.$echarts.init(document.getElementById('experience'));
      this.experienceEcharts.setOption(this.experienceRates);
    },

    // 获取职位数变化趋势
    async getTrendJobs() {
      this.loading.trendJobs = true;
      try {
        const data = await this.$api.platform.getTrendJobs();
        this.getTrendLine(data.list);
        this.loading.trendJobs = false;
      } catch (err) {
        this.loading.trendJobs = false;
        console.log(err);
      }
    },

    // 招聘职位数趋势
    getTrendLine(list = []) {
      const data = [];
      const date = [];
      list.forEach(el => {
        data.push(el.value);
        date.push(el.month);
      });
      this.trendLines.color = ['#35CEFC'];
      this.trendLines.xAxis.data = date;
      this.trendLines.xAxis.axisLabel.interval = 0;
      this.trendLines.series = [
        {
          data: data,
          type: 'line',
          areaStyle: {
            color: 'rgba(53, 206, 252, 0.4)'
          }
        }
      ];
      this.initTrendLine();
    },

    initTrendLine() {
      this.trendEcharts = this.$echarts.init(document.getElementById('trend'));
      this.trendEcharts.setOption(this.trendLines);
    },

    // 获取热门企业、规模分布、行业分布
    async getHotCompany() {
      this.loading.hotCompany = true;
      try {
        const data = await this.$api.platform.getHotCompany({ limit: 10 });
        this.companyList = data.hot || [];
        this.getScaleRate(data.scalePercentile);
        this.getDistributionRate(data.subareaPercentile);
        this.$nextTick(() => {
          if (this.$refs && this.$refs.hotCompany) {
            this.boxHotCompanyHeight = this.$refs.hotCompany.offsetHeight - this.$refs.hotCompanyBox.offsetHeight;
          }
          this.setHotCompanyScrollList();
        });
        this.loading.hotCompany = false;
      } catch (err) {
        this.loading.hotCompany = false;
        console.log(err);
      }
    },

    setHotCompanyScrollList() {
      if (this.timerHotCompany) {
        window.clearInterval(this.timerHotCompany)
      }
      this.scrollHotCompanyHeight = 0;
      this.$refs.hotCompany.style = `transform: translateY(-${this.scrollHotCompanyHeight}px)`;

      this.timerHotCompany = setInterval(() => {
        if (this.scrollHotCompanyHeight + 50 < this.boxHotCompanyHeight) {
          this.scrollHotCompanyHeight = this.scrollHotCompanyHeight + 50;
        } else {
          this.scrollHotCompanyHeight = 0;
        }
        if (this.$refs && this.$refs.hotCompany) {
          this.$refs.hotCompany.style = `transform: translateY(-${this.scrollHotCompanyHeight}px)`;
        }
      }, 3000);
    },

    // 获取企业规模
    getScaleRate(scalePercentile = []) {
      this.scaleRates.legend.right = '5%';
      this.scaleRates.legend.textStyle.width = '50';
      this.scaleRates.legend.textStyle.overflow = 'truncate';
      this.scaleRates.series = [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '62%'],
          center: ['30%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          data: scalePercentile
        }
      ];
      this.initScaleRate();
    },

    initScaleRate() {
      this.scaleEcharts = this.$echarts.init(document.getElementById('scale'));
      this.scaleEcharts.setOption(this.scaleRates);
    },

    // 获取行业分布
    getDistributionRate(subareaPercentile = []) {
      this.distributionRates.legend.right = '5%';
      this.distributionRates.legend.textStyle.width = '50';
      this.distributionRates.legend.textStyle.overflow = 'truncate';
      this.distributionRates.series = [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '62%'],
          center: ['30%', '50%'],
          label: {
            show: false,
            position: 'center'
          },
          data: subareaPercentile
        }
      ];
      this.initDistributionRate();
    },

    initDistributionRate() {
      this.distributionEcharts = this.$echarts.init(document.getElementById('distribution'));
      this.distributionEcharts.setOption(this.distributionRates);
    },

    // 获取热词和使用量
    async getHotKeywords() {
      this.loading.hotKeywords = true;
      try {
        const data = await this.$api.platform.getHotKeywords();
        this.geHotWord(data.list);
        this.loading.hotKeywords = false;
      } catch (err) {
        this.loading.hotKeywords = false;
        console.log(err);
      }
    },

    // 获取热门词
    geHotWord(list) {
      const data = list || [];
      const hotWordsList = [];
      data.forEach(el => {
        hotWordsList.push([el.name, el.value]);
      });
      this.hotWords.fontSizeFactor = 4;
      this.hotWords.list = hotWordsList;
      this.initHotWord();
    },

    initHotWord() {
      this.hotWordEcharts = new Js2WordCloud(document.getElementById('word'));
      this.hotWordEcharts.setOption(this.hotWords);
    },

    // 实时求职数据
    async getActualseeker() {
      this.loading.actualseeker = true;
      try {
        const data = await this.$api.platform.getActualseeker({ limit: 20 });
        this.seekersActualList = data.list || [];
        this.$nextTick(() => {
          if (this.$refs && this.$refs.seekers) {
            this.boxSeekerHeight = this.$refs.seekers.offsetHeight - this.$refs.box.offsetHeight;
          }
          this.setSeekerScrollList();
        });
        this.loading.actualseeker = false;
      } catch (err) {
        this.loading.actualseeker = false;
        console.log(err);
      }
    },

    // 实时企业数据
    async getActualCompany() {
      this.loading.actualCompany = true;
      try {
        const data = await this.$api.platform.getActualCompany({ limit: 20 });
        this.companyActualList = data.list || [];
        this.$nextTick(() => {
          if (this.$refs && this.$refs.company) {
            this.boxCompanyHeight = this.$refs.company.offsetHeight - this.$refs.box.offsetHeight;
          }
          this.setCompanyScrollList();
        });
        this.loading.actualCompany = false;
      } catch (err) {
        this.loading.actualCompany = false;
        console.log(err);
      }
    },

    setSeekerScrollList() {
      if (this.timerSeeker) {
        window.clearInterval(this.timerSeeker)
      }
      this.scrollSeekerHeight = 0;
      this.$refs.seekers.style = `transform: translateY(-${this.scrollSeekerHeight}px)`;

      this.timerSeeker = setInterval(() => {
        if (this.scrollSeekerHeight + 50 < this.boxSeekerHeight) {
          this.scrollSeekerHeight = this.scrollSeekerHeight + 50;
        } else {
          this.scrollSeekerHeight = 0;
        }
        if (this.$refs && this.$refs.seekers) {
          this.$refs.seekers.style = `transform: translateY(-${this.scrollSeekerHeight}px)`;
        }
      }, 3000);
    },

    setCompanyScrollList() {
      if (this.timerCompany) {
        window.clearInterval(this.timerCompany)
      }
      this.scrollCompanyHeight = 0;
      this.$refs.company.style = `transform: translateY(-${this.scrollCompanyHeight}px)`;

      this.timerCompany = setInterval(() => {
        if (this.scrollCompanyHeight + 50 < this.boxCompanyHeight) {
          this.scrollCompanyHeight = this.scrollCompanyHeight + 50;
        } else {
          this.scrollCompanyHeight = 0;
        }
        if (this.$refs && this.$refs.company) {
          this.$refs.company.style = `transform: translateY(-${this.scrollCompanyHeight}px)`;
        }
      }, 3000);
    },

    // 热门区域
    getAreaBar(list) {
      const dataList = list || [];
      const data = [];
      const date = [];
      dataList.forEach(el => {
        data.push(el.value);
        date.push(el.name);
      });
      this.areaBars.color = ['#35CEFC'];
      this.areaBars.xAxis.data = date;
      this.areaBars.xAxis.axisLabel.interval = 0;
      this.areaBars.series = [
        {
          data: data,
          type: 'bar',
          barWidth: '14px'
        }
      ];
      this.initAreaBar();
    },

    initAreaBar() {
      this.areaEcharts = this.$echarts.init(document.getElementById('area'));
      this.areaEcharts.setOption(this.areaBars);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.index {
  height: 100vh;
  background: #000;
  min-width: 1920px;
  min-height: 1080px;
  background: url('./../../assets/imgs/content.png') center top no-repeat;
  background-size: 100% 100%;
  div {
    position: relative;
  }
}
.header {
  height: 80px;
  line-height: 78px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #00efff;
  width: 100%;
  position: relative;
  background: url('./../../assets/imgs/header.png') center top no-repeat;
  background-size: 100% 100%;
  .date-time {
    position: absolute;
    top: 17px;
    height: 16px;
    line-height: 1;
    right: 40px;
    color: #35CEFC;
    font-size: 16px;
    font-weight: 500;
  }
}
.content {
  height: calc(100% - 80px);
  width: 100%;
  padding: 20px 40px 40px;
  > div {
    display: flex;
    justify-content: space-between;
    .bg {
      background-color: rgba(3, 78, 194, 0.15);
      border: 1px solid #0A387B;
    }
  }
}
.top {
  height: 520px;
  // 求职者
  .job_wanted {
    width: 26.5%;
    .lately-conetnt {
      height: calc(100% - 40px);
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      > div {
        width: calc(50% - 10px);
        height: 70px;
        margin: 5px;
        background-color: rgba(53, 206, 252, 0.1);
        line-height: 1;
        display: flex;
        align-items: center;
        img {
          height: 50px;
          width: 50px;
          margin: 0 15px;
          vertical-align: middle;
          border-radius: 50%;
          overflow: hidden;
        }
        div {
          height: 100%;
          width: calc(100% - 80px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-right: 15px;
          p {
            margin: 6px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .name {
            color: #a5b9ff;
            font-size: 14px;
            font-weight: 700;
          }
          .job {
            color: #7a8fdc;
            font-size: 12px;
          }
        }
      }
    }
    .edu_experience {
      width: 100%;
      height: 230px;
      display: flex;
      > div {
        width: 50%;
      }
      #edu,
      #experience {
        height: 190px;
      }
    }
  }

  // 招聘数据
  .info {
    width: calc(47% - 40px);
    margin: 0 20px;
    flex-direction: column;
    display: flex;
    .customStyle {
      height: auto !important;
      padding: 20px 30px !important;
    }
    .info-detail {
      height: 150px;
      padding: 30px 30px;
      display: flex;
      flex-direction: column;
      &.loading::before {
        // background: linear-gradient(90deg, rgba(0, 239, 255, 0.12) 0%, rgba(0, 239, 255, 0.06) 49%, rgba(0, 239, 255, 0.12) 100%);
        // background-color: rgba(11, 2, 54, 0.3);
      }
      &.loading {
        height: 200px !important;
      }
      .line {
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: row;
        &.last {
          margin-top: 20px;
        }
      }
      .number-item {
        flex: 1;
        display: flex;
        text-align: center;
        flex-direction: column ;
        justify-content: center;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          }
        .count {
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 12px;
        }
        .name {
          color: #a5b9ff;
          font-size: 14px;
        }
        .color_FF6295 {
          color: #ff6295;
        }
        .color_35CEFC {
          color: #35cefc;
        }
        .color_FCB30C {
          color: #fcb30c;
        }
      }
    }
    .privacy-info-detail {
      height: 120px;
      border-bottom: none;
    }
    .privacy {
      height: 70px;
      line-height: 70px;
      background: linear-gradient(90deg, rgba(0, 239, 255, 0.12) 0%, rgba(0, 239, 255, 0.06) 49%, rgba(0, 239, 255, 0.12) 100%);
      border: 1px solid #0A387B;
      font-size: 16px;
      font-weight: 600;
      color: #35CEFC;
      display: flex;
      align-items: center;
      padding: 0 20px;
      position: relative;
      .icon {
        margin-right: 10px;
        height: 22px;
        width: 22px;
      }
      .count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 42px;
        > img {
          width: 34px;
          height: 42px;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .job-trend {
      height: calc(100% - 170px);
      margin-top: 20px;
      .trend {
        height: calc(100% - 20px);
        #trend {
          height: 100%;
        }
      }
    }
    .privacy-job-trend {
      height: calc(100% - 210px);
    }
  }

  // 招聘企业
  .company {
    width: 26.5%;
    .company-echarts {
      display: flex;
      > div {
        flex: 1;
      }
    }
    #scale,
    #distribution {
      height: 208px;
    }

    // 热门企业
    .hot-company {
      height: calc(100% - 358px);
      .hot-list {
        height: 100%;
        overflow: hidden;
        position: relative;
        transform: translateY(0);
        transition: all 2s ease-in-out;
      }
    }
  }
}
.bottom {
  height: calc(100% - 540px);
  margin-top: 20px;
  // 热门搜索词
  .hot_search {
    width: 26.5%;
    height: 100%;
    #word {
      height: calc(100% - 140px);
      width: calc(100% - 100px);
      margin: 50px auto;
    }
  }

  // 实时数据
  .actual {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: calc(47% - 40px);
    > div {
      width: calc(50% - 10px);
      height: 100%;
    }
    .seekers-list,
    .company-list {
      height: calc(100% - 60px);
      overflow-y: hidden;
      position: relative;
      transform: translateY(0);
      transition: all 2s ease-in-out;
    }
  }

  // 热门区域
  .hot_area {
    width: 26.5%;
    #area {
      height: calc(100% - 60px);
    }
  }
}
.title {
  height: 60px;
  line-height: 60px;
  color: #35cefc;
  font-size: 20px;
  font-weight: 700;
  padding: 0 20px;
  background-color: rgba(11, 2, 54, 0.3);
  position: relative;
  img {
    height: 22px;
    width: 22px;
    margin-right: 8px;
    vertical-align: middle;
  }
  span {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 14px;
  }
}
.small-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #c2d0ff;
  padding: 0 20px;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(12, 68, 150, 0.2) 0%, rgba(28, 121, 199, 0) 100%);
}
.list {
  .list-info {
    height: 50px;
    line-height: 50px;
    color: #c2d0ff;
    display: flex;
    font-size: 14px;
    padding: 0 10px;
    > div {
      padding: 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .no {
      width: 30px;
    }
    .company-name {
      width: calc(100% - 290px);
    }
    .job {
      width: 100px;
    }
    .delivery {
      width: 160px;
    }
    .name {
      // max-width: 25%;
      color: #a5b9ff;
      padding-right: 5px;
    }
    .company {
      // max-width: 50%;
      color: #a5b9ff;
      padding-right: 5px;
    }
    .opear {
      // max-width: 25%;
      color: #7a8fdc;
      padding: 0;
    }
    .jobs {
      // max-width: 50%;
      color: #35cefc;
      padding-left: 5px;
    }
    .do {
      // max-width: 15%;
      color: #7a8fdc;
      padding-left: 5px;
    }
    .patch {
      // max-width: 35%;
      color: #35cefc;
      padding-left: 5px;
    }
    &:nth-child(2n) {
      background-color: rgba(53, 206, 252, 0.1);
    }
  }
}
.loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8)
}
.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 40px;
  height: 40px;
  background: url('./../../assets/svgs/loading.svg') center center no-repeat;
  background-size: 100% 100%;
  -webkit-animation: changePosition 1s linear infinite;
}
@-webkit-keyframes changePosition{
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
