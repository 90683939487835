const options = {
  list: [],
  gridSize: 6,
  fontSizeFactor: 0.1,
  maxFontSize: 36,
  minFontSize: 12,
  rotationSteps: 45,
  rotateRatio: 0.66,
  ellipticity: 1,
  wait: 10,
  abortThreshold: 2000,
  backgroundColor: 'rgba(255, 255, 255, 0)',
  color() { // 自定义颜色
    const colors = ['#FF518A', '#F9D738', '#F6A60B', '#2C61FF', '#A38BFF', '#35CEFC', '#00B585']
    return colors[parseInt(Math.random() * 7)]
  }
}
export default options
