<template>
  <div class="index">
    <div class="header">
      {{ showBasicInfo.name || '销售数据大屏' }}
      <span class="date-time">{{dateTime}}</span>
    </div>
    <div class="content">
      <div class="left">
        <div class="left-top" :class="loading.basic ? 'loading' :''">
          <div class="bg">
            <p class="count color_8FFCC3">
              <count-to
                :start-val="basicInfo.companyTotal"
                :end-val="showBasicInfo.companyTotal"
                separator=""
                :duration="2000"
              />
            </p>
            <p class="name">企业用户总数</p>
            <p class="yesterday"></p>
          </div>
          <div class="bg">
            <p class="count color_FF6295">
              <count-to
                :start-val="basicInfo.vipCompanyTotal"
                :end-val="showBasicInfo.vipCompanyTotal"
                separator=""
                :duration="2000"
              />
            </p>
            <p class="name">会员总数</p>
            <p class="yesterday"></p>
          </div>
          <div class="bg">
            <p class="count color_FFEE96">
              <count-to
                :start-val="basicInfo.todayFinishedOrder"
                :end-val="showBasicInfo.todayFinishedOrder"
                separator=""
                :duration="1000"
              />
            </p>
            <p class="name">今日成交订单</p>
            <p class="yesterday">昨日： {{basicInfo.yesterdayFinishedOrder}}</p>
          </div>
          <div class="bg">
            <p class="count color_FFAB8A">
              <count-to
                :start-val="basicInfo.todayCompany"
                :end-val="showBasicInfo.todayCompany"
                separator=""
                :duration="1000"
              />
            <p class="name">CRM今日新增客户</p>
            <p class="yesterday">昨日： {{showBasicInfo.yesterdayCompany}}</p>
          </div>
          <div class="bg">
            <p class="count color_A38BFF">
              <count-to
                :start-val="basicInfo.todayFollow"
                :end-val="showBasicInfo.todayFollow"
                separator=""
                :duration="1000"
              />
            </p>
            <p class="name">CRM今日跟进客户</p>
            <p class="yesterday">昨日： {{basicInfo.yesterdayFollow}}</p>
          </div>
        </div>

        <div class="left-middle">
          <div class="today bg">
            <div class="title">
              今日成交金额
              <span>单位：元</span>
            </div>
            <div class="money">
              <p class="count color_FF6295">
                <count-to
                  :start-val="todayCount"
                  :end-val="showTodayCount"
                  separator=""
                  :duration="2000"
                />
              </p>
              <p class="yesterday color_FCB30C">昨日成交：{{yesterdayCount}}</p>
            </div>
          </div>
          <div class="month bg">
            <div class="title">
              本月成交金额
              <span>单位：元</span>
            </div>
            <div class="money">
              <p class="count color_FF6295">
                <count-to
                  :start-val="monthVip"
                  :end-val="showMonthVip"
                  separator=""
                  :duration="2000"
                />
              </p>
              <p v-if="metricsRate" class="yesterday color_FCB30C">目标完成：{{metricsRate}}%</p>
            </div>
          </div>
        </div>

        <div class="left-bottom bg" :class="loading.month ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            本月销售目标达成率
            <span>{{dateMonth}}</span>
          </div>
          <div id="achievement"></div>
          <span v-if="achievementTips" class="tips">暂无目标达成率</span>
        </div>
      </div>
      <div class="right">
        <div class="bg" :class="loading.today ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            昨日成交情况
            <span>单位：元</span>
          </div>
          <div id="today"></div>
          <span v-if="todayTips" class="tips">暂无成交情况</span>
        </div>
        <div class="bg" :class="loading.month ? 'loading' :''">
          <div class="title">
            <img src="./../../assets/svgs/title_icon.svg" alt="" />
            本月成交情况
            <span>单位：元</span>
          </div>
          <div id="month"></div>
          <span v-if="monthTips" class="tips">暂无成交情况</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountTo from 'vue-count-to';
import barLine from './../../assets/js/bar_line';
import utils from './../../utils/utils';
import { setTimeout } from 'timers';

export default {
  name: 'Sale',
  components: {
    CountTo
  },
  data() {
    return {
      dateTime: '',
      dateMonth: '',

      today: {
        start: '',
        end: ''
      },
      yesterday: {
        start: '',
        end: ''
      },
      month: {
        start: '',
        end: ''
      },

      loading: {
        basic: false,
        today: false,
        month: false
      },
      basicInfo: {
        companyTotal: 0,
        todayCompany: 0,
        todayFollow: 0,
        todayFinishedOrder: 0,
        vipCompanyTotal: 0,
        yesterdayCompany: 0,
        yesterdayFollow: 0,
        yesterdayFinishedOrder: 0
      },
      showBasicInfo: {
        companyTotal: 0,
        todayCompany: 0,
        todayFollow: 0,
        todayFinishedOrder: 0,
        vipCompanyTotal: 0,
        yesterdayCompany: 0,
        yesterdayFollow: 0,
        yesterdayFinishedOrder: 0
      },

      showTodayCount: 0,
      todayCount: 0,
      yesterdayCount: 0,

      metricsAllParams: {
        unit: 'MONTH',
        point: ''
      },

      monthVip: 0,
      showMonthVip: 0,
      metricsRate: 0,

      achievementBars: utils.deepCloneObject(barLine),
      achievementTips: false,
      achievementEcharts: null,

      todayDealBars: utils.deepCloneObject(barLine),
      todayTips: false,
      todayDealEcharts: null,

      monthDealBars: utils.deepCloneObject(barLine),
      monthTips: false,
      monthDealEcharts: null
    };
  },
  mounted() {
    console.log(this.$route.query)
    const token = this.$route.query.token || '';
    sessionStorage.setItem('talents_data_view_token', decodeURIComponent(token));
    this.init();
  },
  beforeDestroy() {},

  methods: {
    init() {
      this.dateTime = utils.handleDateObjectTime(new Date(), '.')
      this.dateMonth = utils.handleDateObjectTime(new Date(), '-').substr(0, 7)
      this.getNowDate();

      this.getBaseInfo();
      this.getTodayInfo();
      this.getMonthInfo()

      // 开启定时器
      this.timeInterval()
      this.infoInterval();
    },

    getNowDate() {
      const toDay = utils.handleDateObjectTime(new Date(), '-')
      const toDayStart = toDay.substr(0, 10) + ' 00:00:00'
      const toDayEnd = toDay
      this.today = {
        start: toDayStart,
        end: toDayEnd
      }

      const yesterday = utils.handleDateObjectTime(new Date(new Date().getTime() - (1000 * 60 * 60 * 24)), '-')
      const yesterdayStart = yesterday.substr(0, 10) + ' 00:00:00'
      const yesterdayEnd = yesterday.substr(0, 10) + ' 23:59:59'
      this.yesterday = {
        start: yesterdayStart,
        end: yesterdayEnd
      }

      const month = utils.handleDateObjectTime(new Date(), '-')
      const monthStart = month.substr(0, 7) + '-01 00:00:00'
      const monthEnd = month
      this.month = {
        start: monthStart,
        end: monthEnd
      }
      this.metricsAllParams.point = month.substr(0, 7)
    },

    timeInterval() {
      if (this.timerDate) {
        window.clearInterval(this.timerDate);
      }
      this.timerDate = setInterval(() => {
        this.dateTime = utils.handleDateObjectTime(new Date(), '.')
        this.dateMonth = utils.handleDateObjectTime(new Date(), '-').substr(0, 7)
      }, 1000);
    },

    infoInterval() {
      if (this.timerInfo) {
        window.clearInterval(this.timerInfo);
      }
      this.timerInfo = setInterval(() => {
        this.getNowDate()
        this.getBaseInfo();
        this.getTodayInfo()
        this.getMonthInfo()
      }, 60 * 1000);
    },

    // 获取基础数据
    async getBaseInfo() {
      this.loading.basic = true;
      try {
        const data = await this.$api.sale.getBaseInfo();
        const basicInfo = {}
        const showBasicInfo = {}
        for (const key in data) {
          basicInfo[key] = data[key]
          showBasicInfo[key] = data[key]
        }
        showBasicInfo.name = data.dashboard ? data.dashboard.name : '销售数据大屏'
        document.title = showBasicInfo.name
        this.showBasicInfo = showBasicInfo
        setTimeout(() => {
          this.basicInfo = basicInfo
        }, 3000)
        this.loading.basic = false;

        console.log(data, '获取基础数据');
      } catch (err) {
        this.loading.basic = false;
        console.log(err);
      }
    },

    // 获取今日+昨日数据
    async getTodayInfo() {
      this.loading.today = true;
      try {
        const todayInfo = await this.$api.sale.getSaleInfo(this.today);
        const yesterdayInfo = await this.$api.sale.getSaleInfo(this.yesterday);

        Promise.all([todayInfo, yesterdayInfo]).then((res) => {
          const todayData = res[0]
          const yesterdayData = res[1]

          this.handleTodayData(todayData.list)
          this.getYesterdayDealBar(yesterdayData.list)
          this.loading.today = false;

          console.log(todayData, '获取今日数据');
          console.log(yesterdayData, '获取昨日日数据');
        })
      } catch (err) {
        this.loading.today = false;
        console.log(err);
      }
    },

    // 处理今日数据
    handleTodayData(list = []) {
      let todayCount = 0
      list.forEach(el => {
        const count = el.money ? el.money - 0 : 0
        todayCount += count
      })
      this.showTodayCount = todayCount
      setTimeout(() => {
        this.todayCount = todayCount
      }, 5000);
    },

    // 获取本月销售数据
    async getMonthInfo() {
      this.loading.month = true;
      try {
        const monthInfo = await this.$api.sale.getSaleInfo(this.month);
        const metricsInfo = await this.$api.sale.getMetricsAll(this.metricsAllParams);

        Promise.all([monthInfo, metricsInfo]).then((res) => {
          const monthData = res[0]
          const metricsData = res[1]

          this.getMonthDealBar(monthData.list)
          this.getAchievementBar(monthData.list, metricsData)
          this.loading.month = false;
          console.log(monthData, '获取本月销售数据');
          console.log(metricsData, '获取所有销售数据');
        })
      } catch (err) {
        this.loading.month = false;
        console.log(err);
      }
    },

    // 本月销售目标达成率
    getAchievementBar(list = [], metricsData = {}) {
      const data = [];
      const date = [];
      let monthVip = 0;
      const metricsList = metricsData.salesman || []
      list.forEach(el => {
        const money = el.money ? el.money - 0 : 0
        monthVip += money
        for (let d = 0; d < metricsList.length; d++) {
          if (el.username === metricsList[d].username && metricsList[d].metric && (metricsList[d].metric.objective - 0)) {
            const objective = metricsList[d].metric.objective - 0
            const rate = objective ? (money / metricsList[d].metric.objective * 100).toFixed(2) : (money * 100).toFixed(2)
            // data.push(rate);
            data.push({
              name: el.username,
              value: rate > 100 ? 100 : rate,
              rate: rate
            });
            date.push(el.username);
            break
          }
        }
      })
      this.showMonthVip = monthVip
      const metricsCount = metricsData.team && metricsData.team.objective ? metricsData.team.objective - 0 : 0
      this.metricsRate = metricsCount ? (monthVip / metricsCount * 100).toFixed(2) : 0
      setTimeout(() => {
        this.monthVip = monthVip
      }, 5000);

      this.achievementBars.color = ['#35CEFC'];
      this.achievementBars.yAxis[0].show = false;
      this.achievementBars.yAxis[0].max = 100;
      this.achievementBars.xAxis.data = date;
      this.achievementTips = (date.length === 0)
      this.achievementBars.xAxis.axisLabel.interval = 0;
      this.achievementBars.tooltip.formatter = function (params) {
        const str = params[0].data.name + '：' + params[0].data.rate + '%'
        return str
      }
      this.achievementBars.series = [
        {
          data: data,
          type: 'bar',
          barWidth: '50px',
          showBackground: true,
          label: {
            show: true,
            position: 'top',
            color: '#C2D0FF',
            formatter: function (params) {
              const str = params.data.rate + '%'
              return str
            }
          },
          backgroundStyle: {
            color: 'rgba(64, 187, 254, 0.2)'
          }
        }
      ];
      this.initAchievementBar();
    },

    initAchievementBar() {
      this.achievementEcharts = this.$echarts.init(document.getElementById('achievement'));
      this.achievementEcharts.setOption(this.achievementBars);
    },

    // 昨日成交情况
    getYesterdayDealBar(list = []) {
      const data = [];
      const date = [];
      let yesterdayCount = 0;

      list.forEach((el, i) => {
        const money = el.money ? el.money - 0 : 0
        yesterdayCount += money
        data.unshift(money);
        date.unshift(el.username);
      });
      this.yesterdayCount = yesterdayCount;
      this.todayDealBars.color = ['#35CEFC'];
      this.todayDealBars.grid.left = '100';
      this.todayDealBars.yAxis[0].splitLine.show = false;
      this.todayDealBars.yAxis[0].type = 'category';
      this.todayDealBars.yAxis[0].data = date;
      this.todayTips = (date.length === 0)

      this.todayDealBars.xAxis.type = 'value';
      this.todayDealBars.xAxis.axisLine.show = true;
      this.todayDealBars.xAxis.splitLine = {
        show: false
      };
      this.todayDealBars.series = [
        {
          data: data,
          type: 'bar',
          barWidth: '18px',
          label: {
            show: true,
            position: 'right',
            color: '#C2D0FF'
          }
        }
      ];
      this.initTodayDealBar();
    },

    initTodayDealBar() {
      this.achievementEcharts = this.$echarts.init(document.getElementById('today'));
      this.achievementEcharts.setOption(this.todayDealBars);
    },

    // 本月成交情况
    getMonthDealBar(list = []) {
      const data = [];
      const date = [];
      list.forEach((el, i) => {
        const money = el.money ? el.money - 0 : 0
        data.push(money);
        date.push(el.username);
      });
      this.monthDealBars.color = ['#35CEFC'];
      this.monthDealBars.grid.left = '100';
      this.monthDealBars.yAxis[0].splitLine.show = false;
      this.monthDealBars.yAxis[0].type = 'category';
      this.monthDealBars.yAxis[0].data = date;
      this.monthTips = (date.length === 0)

      this.monthDealBars.xAxis.type = 'value';
      this.monthDealBars.xAxis.axisLine.show = true;
      this.monthDealBars.xAxis.splitLine = {
        show: false
      };
      this.monthDealBars.series = [
        {
          data: data,
          type: 'bar',
          barWidth: '18px',
          label: {
            show: true,
            position: 'right',
            color: '#C2D0FF'
          }
        }
      ];
      this.initMonthDealBar();
    },

    initMonthDealBar() {
      this.achievementEcharts = this.$echarts.init(document.getElementById('month'));
      this.achievementEcharts.setOption(this.monthDealBars);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.index {
  height: 100vh;
  background: #000;
  min-width: 1920px;
  min-height: 1080px;
  background: url('./../../assets/imgs/content.png') center top no-repeat;
  background-size: 100% 100%;
  div {
    position: relative;
  }
}
.header {
  height: 80px;
  line-height: 78px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  color: #00efff;
  width: 100%;
  position: relative;
  background: url('./../../assets/imgs/header.png') center top no-repeat;
  background-size: 100% 100%;
  .date-time {
    position: absolute;
    top: 17px;
    height: 16px;
    line-height: 1;
    right: 40px;
    color: #35CEFC;
    font-size: 16px;
    font-weight: 500;
  }
}
.content {
  height: calc(100% - 80px);
  width: 100%;
  padding: 20px 40px 40px;
  display: flex;
  justify-content: space-between;
  .left {
    width: calc(70% - 10px);
    .left-top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(17% - 20px);
      margin-bottom: 20px;
      > div {
        flex: 1;
        margin-right: 20px;
        height: 100%;
        display: flex;
        text-align: center;
        flex-direction: column ;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-height: 20px;
          // height: 40px;
          // line-height: 40px;
        }
        .count {
          font-size: 50px;
          margin-bottom: 8px;
        }
        .name {
          color: #a5b9ff;
          font-size: 14px;
          margin-bottom: 4px;
        }
        .yesterday {
          color: #35CEFC;
          font-size: 14px;
        }
        .color_8FFCC3 {
          color: #8FFCC3;
        }
        .color_FF6295 {
          color: #FF6295;
        }
        .color_FFAB8A {
          color: #FFAB8A;
        }
        .color_FFEE96 {
          color: #FFEE96;
        }
        .color_A38BFF {
          color: #A38BFF;
        }
      }
    }
    .left-middle {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: calc(43% - 20px);
      margin-bottom: 20px;
      .today {
        width: calc(40% - 12px);
        color: #FCB30C;
        font-size: 120px;
        height: 100%;
        .money {
          height: calc(100% - 60px);
          display: flex;
          text-align: center;
          flex-direction: column ;
          justify-content: center;
          align-items: center;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          min-height: 30px;
        }
        .count {
          height: 150px;
          line-height: 150px;
          margin-bottom: 30px;
        }
        .yesterday {
          font-size: 24px;
        }
      }
      .month {
        width: calc(60% - 8px);
        color: #FF4747;
        font-size: 150px;
        .money {
          height: calc(100% - 60px);
          display: flex;
          text-align: center;
          flex-direction: column ;
          justify-content: center;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .count {
          height: 150px;
          line-height: 150px;
          margin-bottom: 30px;
        }
        .yesterday {
          font-size: 24px;
        }
      }
    }
    .left-bottom {
      height: 40%;
      position: relative;
      #achievement {
        height: calc(100% - 60px);
      }
    }
  }
  .right {
    width: calc(30% - 10px);
    display: flex;
    flex-direction: column ;
    justify-content: space-between;
    > div {
      height: calc(50% - 10px);
      position: relative;
      #today, #month {
        height: calc(100% - 60px);
      }
    }
  }
  .bg {
    background-color: rgba(3, 78, 194, 0.15);
    border: 1px solid #0A387B;
  }
}

.title {
  height: 60px;
  line-height: 60px;
  color: #35cefc;
  font-size: 20px;
  font-weight: 700;
  padding: 0 20px;
  background-color: rgba(11, 2, 54, 0.3);
  position: relative;
  img {
    height: 22px;
    width: 22px;
    margin-right: 8px;
    vertical-align: middle;
  }
  span {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: 14px;
  }
}
.small-title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #c2d0ff;
  padding: 0 20px;
  font-weight: 700;
  background: linear-gradient(90deg, rgba(12, 68, 150, 0.2) 0%, rgba(28, 121, 199, 0) 100%);
}
.tips {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #35cefc;
  font-size: 24px;
}
.loading::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8)
}
.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  width: 40px;
  height: 40px;
  background: url('./../../assets/svgs/loading.svg') center center no-repeat;
  background-size: 100% 100%;
  -webkit-animation: changePosition 1s linear infinite;
}
@-webkit-keyframes changePosition{
  0%{
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
