import Vue from 'vue';
import Router from 'vue-router';
import Index from '@/views/index/index';
import Sale from '@/views/sale/index';
Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/index',
      name: 'Index',
      component: Index
    },
    {
      path: '/sale',
      name: 'Sale',
      component: Sale
    },
    {
      path: '/h5-data-view',
      name: 'H5-data-view',
      component: () => import('@/views/H5-data-view/index')
    },
    {
      path: '/custom',
      name: 'custom',
      component: () => import('@/views/custom/index')
    }
  ]
});
