import Vue from 'vue';
import App from './App';
import router from './router';
import * as echarts from 'echarts';
import api from './api';

Vue.prototype.$echarts = echarts;

Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, '$api', { value: api });

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
