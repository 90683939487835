const options = {
  color: ['#FF518A', '#F9D738', '#F6A60B', '#2C61FF', '#A38BFF', '#35CEFC', '#00B585'],
  tooltip: {
    trigger: 'item'
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    top: 'center',
    textStyle: {
      color: '#A6C3F6'
    },
    icon: 'circle'
  },
  series: []
}
export default options
