<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
  // data() {
  //   return {
  //     token: null
  //   }
  // },
  // created() {
  //   this.token = this.$route.query.token || '';
  //   sessionStorage.setItem('talents_data_view_token', decodeURIComponent(this.token));
  //   console.log('ttttttttttt', this.token);
  //   console.log('ddddddddddd', sessionStorage.getItem('talents_data_view_token'))
  //   this.IsH5();
  // },
  // methods: {
  // }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
